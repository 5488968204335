import { getEnv } from "@fep/env";
import { Stage } from "@fep/env/types";

export const deepLinkUrl = {
  get _env() {
    return getEnv(process.env.TARGET_ENV as Stage);
  },
  get profile() {
    return `${this._env.DEEPLINK_URL}/profile`;
  },
  get progression() {
    return `${this._env.DEEPLINK_URL}/progression`;
  }
};
