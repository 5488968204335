import { FepError, gigya, transactionalEmailUrl } from "@fep/services";

export async function sendVerificationEmail() {
  const url = transactionalEmailUrl.sendVerificationEmail;
  const isLoggedIn = await gigya.isLoggedIn();

  if (!isLoggedIn) {
    return false;
  }

  const { profile, systemData } = await gigya.getAccountInfo();

  if (systemData.isEmailVerified) return true;

  const body = JSON.stringify({
    uid: profile.uid,
    siteId: window.gigya.apiKey
  });

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body
  });

  if (!response.ok) {
    throw new FepError(`HTTP error! Status: ${response.status}`, response);
  }

  return true;
}
