import { getEnv } from "@fep/env";
import { Stage } from "@fep/env/types";

export const transactionalEmailUrl = {
  get _env() {
    return getEnv(process.env.TARGET_ENV as Stage);
  },
  get sendVerificationEmail() {
    return `${this._env.TR_EMAIL_URL}/sendVerificationEmail`;
  },
  get createPassword() {
    return `${this._env.TR_EMAIL_URL}/createPassword`;
  },
  get resetPassword() {
    return `${this._env.TR_EMAIL_URL}/resetPassword`;
  }
};
