import { FepError, transactionalEmailUrl } from "@fep/services";

export async function checkEmailPrereg(email: string): Promise<{ preregExists: boolean }> {
  const url = transactionalEmailUrl.createPassword;
  const body = JSON.stringify({
    email,
    siteId: window.gigya.apiKey
  });

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body
  });

  if (!response.ok) {
    throw new FepError(`HTTP error! Status: ${response.status}`, response);
  }

  return response.json();
}
