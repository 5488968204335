import { deepLinkUrl, FepError } from "@fep/services";

export async function getImTokenData(imtoken: string): Promise<{ profile: { email: string } }> {
  const url = deepLinkUrl.profile;
  const response = await fetch(`${url}?imtoken=${imtoken}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new FepError(`HTTP error! Status: ${response.status}`, response);
  }

  return await response.json();
}
