import { IPageLocation } from "@fep/interfaces";
import { logger } from "@fep/services";

export function getPageLocation(): IPageLocation {
  try {
    if (window.top) {
      return PageLocation.fromLocation(window.top.location);
    }
  } catch (error) {
    logger.warn("Could not get location from window.top object", error);
  }
  // Fallback to document.referrer in case of cross-origin restrictions only
  return document.referrer
    ? PageLocation.fromURL(new URL(document.referrer))
    : PageLocation.fromLocation(window.location);
}

class PageLocation implements IPageLocation {

  private constructor(
    public readonly href: string,
    public readonly pathname: string,
    public readonly search: string,
    public readonly origin: string
  ) {
  }

  public static fromLocation(location: Location) {
    return new this(location.href, location.pathname, location.search, location.origin);
  }

  public static fromURL(url: URL) {
    return new this(url.href, url.pathname, url.search, url.origin);
  }
}

export const PageLocationForUnitTesting = {
  PageLocation
};
