type GigyaErrorOptions = Partial<Response> & {
  errorCode?: number;
  errorDetails?: string;
  errorMessage?: string;
};

type FepErrorOptions = ErrorOptions & GigyaErrorOptions & {
  code?: number;
  details?: string;
  displayMessage?: string;
};

export class FepError extends Error {
  name: string;
  code?: number;
  details?: string;
  time: string;
  displayMessage: string;

  constructor(message?: string, options?: FepErrorOptions) {
    super(message, options);

    this.name = this.constructor.name;
    this.code = options?.errorCode ?? options?.code;
    this.details = options?.errorDetails ?? options?.details;
    this.time = new Date().toISOString();
    this.message = options?.errorMessage ?? message ?? "Unknown FEP error";
    this.displayMessage = options?.displayMessage ?? this.message;

    Object.setPrototypeOf(this, new.target.prototype);
  }
}
